<template lang="">
    <div>

        <b-card header="ຄົ້ນຫາ...." class="m-3">
            <b-row>
                <b-col>
                    <b-form-group label-cols="4" label-cols-lg="2" label-size="lg" label="ຊື່:" label-for="input-lg">
                        <b-form-input v-model="fname"  size="lg"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="lg" label="ນາມສະກຸນ:" label-for="input-lg">
                        <b-form-input  v-model="lname"  size="lg"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-button variant="primary" @click="onFinditems" size="lg"> <b-icon icon="search"></b-icon> ຊອກຫາ....</b-button>
                </b-col>
            </b-row>
        </b-card>


        <b-list-group class="m-3" >
            <b-list-group-item >
                 <b-row>
                    <b-col md="1">ລຳດັບ</b-col>
                    <b-col md="2">ຊື່ ແລະ ນາມສະກຸນ</b-col>
                    <!-- <b-col md="2">ວັນເດືອນປີເກິດ, ອາຍຸ</b-col> -->
                    <b-col md="3">ວັນທີ, ເວລານັດ</b-col>
                    <!-- <b-col md="1">QR</b-col> -->
                    <b-col  >ສະຖານະ</b-col>
                </b-row>
            </b-list-group-item>
           
            <b-list-group-item  v-for="(item,index) in items" :key="item.queue_id">
                
                <b-row>
                    <b-col md="1"> {{index+1}} </b-col>
                    <b-col md="2">{{item.fname}} {{item.lname}}</b-col>
                    <!-- <b-col md="2">{{item.dob |formatDate }}, {{item.age}} ປີ</b-col> -->
                    <b-col md="3" class="text-success">{{item.queue_date | dateString }} </b-col>
                    <b-col md="1"> 
                        
                        <!-- <b-link :to="'/qr/'+item.queue_id">QR</b-link> <b-icon src="../assets/icons8-qr-code-48.png"></b-icon>  -->
                        
                        <!-- <b-link  @click="gotoqr(item.queue_id)" >QR</b-link> <b-icon src="../assets/icons8-qr-code-48.png"></b-icon>   -->

                    </b-col>
                    <b-col>{{item.status}}</b-col>
                </b-row>
            </b-list-group-item>
        </b-list-group>
        <!-- <infinite-loading @infinite="infiniteHandler" spinner="spiral">
            <div slot="spinner">Loading...</div>
            <div slot="no-more">No more data</div>
            <div slot="no-results">No results data</div>
        </infinite-loading> -->
    

        
    </div>
</template>

<script>
import axios from "axios";
//import InfiniteLoading from "vue-infinite-loading";
export default {
    data() {
        return {
            fname: "",
            lname: "",
            page: 0,
            field: '',
            find: '',
            menuid: this.$route.query.id,
            pkey: "",
            table: "",
            fields: [],
            items: [],
        }

    },

    components: {
        // InfiniteLoading
    },
    created() {

    },
    methods: {
         
        gotoqr(queu_id){
            axios
            .get(
                this.$store.getters.getBaseUrl + "/v_queue_details/queue_id/" + queu_id, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                    "Accept-Encoding": "identity"
                }
            }
            )
            .then((response) => {
                // JSON responses are automatically parsed.
                this.fld = response.data[0]
                //  let  qr= [this.fld.queue_id, this.fld.queue_no, this.fld.fname, this.fld.lname, this.fld.age, this.fld.sex, this.fld.dob, this.fld.queue_date,this.fld.start_time,this.fld.end_time, this.fld.ca1, this.fld.ca2, this.fld.ca3, this.fld.ca4, this.fld.ca5, this.fld.ca6, this.fld.ca7, this.fld.ca8, this.fld.ca9, this.fld.ca10, this.fld.ca11, this.fld.ca12, this.fld.ca13, this.fld.ca14, this.fld.ca15]
              //  let qr = [this.fld.queue_id, this.fld.queue_no, this.fld.fname, this.fld.lname, this.fld.age, this.fld.sex, this.fld.dob, this.fld.queue_date, this.fld.start_time, this.fld.end_time, this.fld.ca1]

               // this.qr_code = qr.join("|")
             

               localStorage.setItem("qr_code",JSON.stringify(this.fld) )
                this.$router.push({path: '/qr/'+queu_id});

            })

            
        },


        onFinditems() {

            axios
                .get(
                    this.$store.getters.getBaseUrl +
                    "/findqueue?fname=" + this.fname + "&lname=" + this.lname,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                            "Accept-Encoding": "identity"
                        }
                    }
                )
                .then((response) => {
                    this.items = response.data.items;
                })
        }

    },
    filters: {
        dateString(dd) {
            let op = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
            const d = new Date(dd);

            return d.toLocaleString('lao', op);
        },

        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        formatDate(dd) {
            let d = new Date(dd)
            return [
                (d.getDate().toString().padStart(2, '0')),
                (d.getMonth() + 1).toString().padStart(2, '0'),
                d.getFullYear(),
            ].join('/');
        },
    }

}
</script>

<style lang="">

</style>